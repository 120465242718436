.app-bar {
    background: rgb(121,196,200);
    background: linear-gradient(90deg, rgba(121,196,200,1) 19%, rgba(241,241,242,1) 62%, rgba(193,245,255,1) 100%);
}

.app-content {
    padding-top: 40px;
}

/** Should this be SASS? **/
.app-content-body {
    padding-top: 15px;
    margin-left: 35px;
    padding-bottom: 10px;
}

.center-screen-content {
    min-height: 100vh;
}

.paper-content {
    padding-top: 15px;
    padding-bottom: 10px;

}