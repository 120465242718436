
.app-bar {
    background: rgb(121,196,200);
    background: linear-gradient(90deg, rgba(121,196,200,1) 19%, rgba(241,241,242,1) 62%, rgba(193,245,255,1) 100%);
}

.welcome-banner {
    background: #cbc4c4;
    height :370px;
}

.welcome-banner-text-area {
    height: 300px;
    width: 10px;

}
.welcome-banner-text-desc {
    background: #cbc4c4;
    font-family: "Arial", "Helvetica", sans;
    font-size: 32px;
}
.responsive-img {
    width: 100%;
    height: auto;
}